export * from "ce/pages/AppIDE/components/AppIDEModals";

import React from "react";
import { AppIDEModals as CEAppIDEModals } from "ce/pages/AppIDE/components/AppIDEModals";
import { GenerateWorkflowApprovalPage } from "ee/pages/GenerateWorkflowApprovalPage";
import { IDE_TYPE } from "ee/IDE/Interfaces/IDETypes";
import { ShowWarningPublishAnvilApplicationModal } from "./ShowWarningPublishAnvilApplicationModal";

export function AppIDEModals() {
  return (
    <>
      <CEAppIDEModals />
      <GenerateWorkflowApprovalPage ideType={IDE_TYPE.App} />
      <ShowWarningPublishAnvilApplicationModal />
    </>
  );
}
