import type { UIModulesListProps as CE_UIModulesListProps } from "ce/pages/AppIDE/components/WidgetAdd/UIModulesList";
import { getAllModules } from "ee/selectors/modulesSelector";
import type { Module } from "ee/constants/ModuleConstants";
import type { Package } from "ee/constants/PackageConstants";
import { getPackages } from "ee/selectors/packageSelectors";
import { groupBy, sortBy } from "lodash";
import UIEntitySidebar from "pages/Editor/widgetSidebar/UIEntitySidebar";
import React from "react";
import { useSelector } from "react-redux";
import WidgetFactory from "WidgetProvider/factory";
import type { WidgetCardsGroupedByTags } from "constants/WidgetConstants";

interface UIModulesListProps extends CE_UIModulesListProps {}

function getUIModulesCardList(modules: Module[], packages: Package[]) {
  const widgetConfigs = WidgetFactory.getConfigs();
  const widgetConfigsArray = Object.values(widgetConfigs);

  const moduleWidgets = widgetConfigsArray.filter((config) => {
    return config.type.startsWith("MODULE_WIDGET_");
  });

  const cards = moduleWidgets.map((config) => {
    const {
      columns,
      detachFromLayout = false,
      displayName,
      displayOrder,
      iconSVG,
      key,
      moduleUUID,
      rows,
      searchTags,
      tags,
      thumbnailSVG,
      type,
    } = config;

    const { IconCmp, ThumbnailCmp } = WidgetFactory.getWidgetMethods(
      config.type,
    );

    const module = modules.find((module) => module.moduleUUID === moduleUUID);
    const pkg = packages.find((pkg) => pkg.id === module?.packageId);

    return {
      key,
      type,
      rows,
      columns,
      detachFromLayout,
      displayName,
      displayOrder,
      icon: iconSVG,
      thumbnail: thumbnailSVG,
      IconCmp,
      ThumbnailCmp,
      searchTags,
      tags,
      isDynamicHeight: false,
      packageName: pkg?.name || "General",
    };
  });

  return sortBy(cards, ["displayName"]);
}

const EMPTY_ENTITY_LOADING = {};

const UIModulesList = ({ focusSearchInput }: UIModulesListProps) => {
  const modules = useSelector(getAllModules);
  const packages = useSelector(getPackages);
  const cards = getUIModulesCardList(
    Object.values(modules),
    Object.values(packages),
  );
  const groupedCards = groupBy(
    sortBy(cards, ["packageName"]),
    "packageName",
  ) as unknown as WidgetCardsGroupedByTags;

  return (
    <UIEntitySidebar
      cards={cards}
      entityLoading={EMPTY_ENTITY_LOADING}
      focusSearchInput={focusSearchInput}
      groupedCards={groupedCards}
      isActive
    />
  );
};

export default UIModulesList;
