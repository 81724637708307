export * from "ce/pages/Editor/EntityEditor/ConvertToModuleInstanceCTA";
import type { ConvertToModuleInstanceCTAProps } from "ce/pages/Editor/EntityEditor/ConvertToModuleInstanceCTA";

import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Flex, MenuItem, Tag } from "@appsmith/ads";

import PackageListMenu from "./PackageListMenu";
import { convertEntityToInstance } from "ee/actions/moduleInstanceActions";
import { getCurrentAppWorkspace } from "ee/selectors/selectedWorkspaceSelectors";
import { hasCreatePackagePermission } from "ee/utils/permissionHelpers";
import { getShowQueryModule } from "ee/selectors/moduleFeatureSelectors";
import usePackageListToConvertEntity from "./usePackageListToConvertEntity";
import type { OnItemClickProps } from "./PackageListMenu";
import {
  BETA_TAG,
  CONVERT_MODULE_CTA_TEXT,
  createMessage,
} from "ee/constants/messages";
import { noop } from "lodash";
import { useFeatureFlag } from "utils/hooks/useFeatureFlag";
import { FEATURE_FLAG } from "ee/entities/FeatureFlag";

function ConvertToModuleInstanceCTA({
  canCreateModuleInstance,
  canDeleteEntity,
  entityId,
  moduleType,
}: ConvertToModuleInstanceCTAProps) {
  const packages = usePackageListToConvertEntity();
  const dispatch = useDispatch();
  const currentWorkspace = useSelector(getCurrentAppWorkspace);
  const canCreateNewPackage = hasCreatePackagePermission(
    currentWorkspace?.userPermissions,
  );
  const showQueryModule = useSelector(getShowQueryModule);
  const canConvertEntity = canDeleteEntity && canCreateModuleInstance;
  const isGitPackageEnabled = useFeatureFlag(
    FEATURE_FLAG.release_git_package_enabled,
  );
  const createNewModuleInstance = useCallback(
    ({ packageId }: OnItemClickProps) => {
      dispatch(
        convertEntityToInstance({
          moduleType,
          publicEntityId: entityId,
          packageId,
          initiatedFromPathname: location.pathname,
        }),
      );
    },
    [entityId, moduleType],
  );

  const buttonTitle = (
    <Flex alignItems="center" gap="spaces-2">
      {createMessage(CONVERT_MODULE_CTA_TEXT)}
      {!isGitPackageEnabled && (
        <Tag isClosable={false}>{createMessage(BETA_TAG)}</Tag>
      )}
    </Flex>
  );

  if (!showQueryModule) {
    return null;
  }

  if (packages.length === 0) {
    const isDisabled = !canConvertEntity || !canCreateNewPackage;
    const onClickHandler = () =>
      isDisabled ? noop : createNewModuleInstance({ packageId: undefined });

    return (
      <MenuItem
        data-testid="t--convert-module-btn"
        onSelect={onClickHandler}
        startIcon="create-module"
      >
        {buttonTitle}
      </MenuItem>
    );
  }

  return (
    <PackageListMenu
      canCreateNewPackage={canCreateNewPackage}
      isDisabled={!canConvertEntity}
      onItemClick={createNewModuleInstance}
      packages={packages}
      title={buttonTitle}
    />
  );
}

export default ConvertToModuleInstanceCTA;
